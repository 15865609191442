import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { removeFromCart, validateAddon } from '../../store/actions';
import AddCartItem from '../add-cart-item';
import CartSubtotal from '../cart-subtotal';
import CartItem from '../cart-item';
import CouponInput from '../coupon-input';
import checkMobile from '../../hooks/checkMobile';
import { createNavigateTo, pageLinks } from '../../helpers/navigation';
import { selectCart, showAutoRenewalMessage } from '../../store/selectors';
import { checkIfPathProtected, isUserLogged, isAddonInstance } from '../../helpers/utils';
import BannerBtcDiscountShoppingCart from '../banner-btc-discount-shopping-cart';
import styles from './cart.module.scss';

const stateSelector = createStructuredSelector({
  cartData: selectCart,
  showAutoRenewal: showAutoRenewalMessage,
});

const Cart = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { cartData, showAutoRenewal } = useSelector(stateSelector);
  const { products, subTotal, productCount, totalDiscount, showInitialDiscount } = cartData;

  const itemStrId = productCount === 1 ? 'item' : 'items';
  const itemStr = intl.formatMessage({ id: itemStrId });
  const subtotalStr = intl.formatMessage({ id: 'cart_subtotal' });
  const subtotalTxt = `${subtotalStr} (${productCount} ${itemStr}):`;

  const isMobile = checkMobile();

  const addItemToCard = createNavigateTo(pageLinks.pricing);

  const toCheckOut = () => {
    if (isAddonInstance && isUserLogged()) {
      return dispatch(
        validateAddon(products.map((item) => ({ ...item, successActionType: 'MOVE_TO_CHECKOUT' })))
      );
    }
    return createNavigateTo(checkIfPathProtected(pageLinks.checkout))();
  };

  const deleteProduct = (id) => {
    dispatch(removeFromCart(id));
  };

  const renderSubTotal = () => (
    <>
      <div className={styles.divider} />
      <div className={styles.subtotalContainer}>
        {showInitialDiscount && <BannerBtcDiscountShoppingCart />}
        <div className={styles.itemsSubtotal}>
          <p className={styles.subtotal}>
            {subtotalTxt}
            <span>{`$${subTotal}`}</span>
          </p>
        </div>
      </div>
    </>
  );

  return (
    <div>
      <div className={styles.row}>
        <div className={styles.columnLeft}>
          <div>
            <h2 className={styles.header}>{intl.formatMessage({ id: 'my_shopping_cart' })}</h2>
          </div>

          <div className={styles.itemsInCart}>
            <AddCartItem onAdd={addItemToCard} />
            <ul className={styles.cartList}>
              {products.map(
                ({
                  name,
                  price,
                  productName,
                  isAnnual,
                  recurringPrice,
                  color,
                  bgColor,
                  icon,
                  intervalSize,
                }) => (
                  <CartItem
                    key={name}
                    name={name}
                    price={price}
                    color={color}
                    icon={icon}
                    bgColor={bgColor}
                    intervalSize={intervalSize}
                    onDelete={() => deleteProduct(productName)}
                    recurrentPrice={isAnnual ? null : recurringPrice}
                  />
                )
              )}
            </ul>
          </div>
          <div>{!isMobile && renderSubTotal()}</div>
        </div>

        <div className={styles.columnRight}>
          <div className={styles.coupon}>{isUserLogged() && <CouponInput />}</div>

          <CartSubtotal
            itemsCount={productCount}
            subtotal={subTotal}
            onCheckoutClick={toCheckOut}
            totalDiscount={totalDiscount}
            showAutoRenewalMessage={showAutoRenewal}
          />
          {isMobile && renderSubTotal()}
        </div>
      </div>
    </div>
  );
};

export default Cart;
