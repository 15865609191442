import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import SEO from '../components/seo';
import Layout from '../components/layout';
import Cart from '../components/cart';
import ArrowBack from '../components/arrowBack';
import BannerRefferal from '../components/banner-refferal';
import { getInstanceName } from '../helpers/utils';
import { pageLinks } from '../helpers/navigation';
import styles from '../styles/pages/shopping-cart.module.scss';

const ShoppingCart = () => {
  const intl = useIntl();

  const backToTxt = intl.formatMessage({ id: 'back_to_plans' });
  const siteName = getInstanceName();
  const title = intl.formatMessage({ id: 'pageTitle.shoppingCart' });

  return (
    <>
      <SEO title={`${title} - ${siteName}`} />
      <Layout pageWrapperClassName="page_wrapper">
        <div className="refferalWrapper">
          <BannerRefferal />
        </div>

        <div className={styles.shoppingCart}>
          <ArrowBack text={backToTxt} navigationTo={pageLinks.pricing} />
          <div className={styles.cart}>
            <Cart />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ShoppingCart;
